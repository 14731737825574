
.Droppin {

 color:black; 
 cursor: pointer;



}


.card {



}


.Information {

color: black;

}

.Rating {



}


.Parameter {

color: black;
flex: content;
flex-direction: column;


}

.Params {
    
    color: black;
    flex: content;
    flex-direction: left;
    margin-bottom: 3px;

    
    
    }


.DeviceName {

    color: black;
    flex: content;
    flex-direction: column;


}


.Value {

    color: black;
    flex: content;
    flex-direction: column;
    
    
    }

.Review {


}


.Stars {

    color: gold;
    font-size: small;


}

label {

color: tomato;
font-size: 13px;
border-bottom: 1px solid tomato;
margin: 3px 3px;

}


.error {
    top: 10%;
    text-align: center;
    color: tomato;
    font-size: 20px;
    flex: content;
    flex-direction: column;
    -ms-flex-align: center;
   
    margin: 3px 3px;
    
    }
    

    
.connect {
    background: aliceblue;
    position: relative;
    text-align: center;
    color: tomato;
    font-size: 20px;
    flex: content; 
    
    
    /* margin-left: 600px;
    margin-right: 600px;
    margin-top: 100px; */

    
    }
    